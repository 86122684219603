import React, {useState, useEffect} from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import config from '../config';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');
var bootprompt = require('bootprompt');
var ReactFlot = require('react-flot');

export default function Dashboard() {
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [expensesByCategory, setExpensesByCategory] = useState([]);
  const [incomeByCategory, setIncomeByCategory] = useState([]);
  const expensesByCategoryChartOptions = {
    series: {
        pie: {
            show: true,
            radius: 1,
						label: {
							show: true,
							radius: 3/4,
							formatter: labelFormatter,
							background: {
								opacity: 0.5,
								color: "#000"
							}
						}
        }
    },
    legend: {
      show: true
    }
};
const incomeByCategoryChartOptions = {
  series: {
      pie: {
          show: true,
          radius: 1,
          label: {
            show: true,
            radius: 3/4,
            formatter: labelFormatter,
            background: {
              opacity: 0.5,
              color: "#000"
            }
          }
      }
  },
  legend: {
    show: true
  }
};
const [expensesByCategoryChartData, setExpensesByCategoryChartData] = useState([]);
const [incomeByCategoryChartData, setIncomeByCategoryChartData] = useState([]);
const [itemExpenses, setItemExpenses] = useState([]);
const [expensesByEntity, setExpensesByEntity] = useState([]);
const [averageSpendPerDay, setAverageSpendPerDay] = useState(0);
const [averageNrOfTransactions, setAverageNrOfTransactions] = useState(0);
const [medianSpendPerDay, setMedianSpendPerDay] = useState(0);
const [averageSpendPerMonth, setAverageSpendPerMonth] = useState(0);
const [currentTab, setCurrentTab] = useState("info");

  function loadStats() {
    axios.get(config.BASE_URL + '/api/get-stats')
    .then(function(response) {
      console.log(response.data);
      if (response.data.status == "OK") {
        setTotalTransactions(response.data.data.count);
        setTotalIncome(response.data.data.totalIncome);
        setTotalExpense(response.data.data.totalExpense);
        setAverageSpendPerDay(response.data.data.average);
        setAverageNrOfTransactions(response.data.data.average_nr_of_transactions);
        setMedianSpendPerDay(response.data.data.median_spend_per_day);
        setAverageSpendPerMonth(response.data.data.average_spend_per_month);
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function loadExpensesByCategory() {
    axios.get(config.BASE_URL + '/api/get-expenses-by-category')
    .then(function(response) {
      console.log(response.data);
      if (response.data.status == "OK") {
        setExpensesByCategory(response.data.data);
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function loadIncomeByCategory() {
    axios.get(config.BASE_URL + '/api/get-income-by-category')
    .then(function(response) {
      console.log(response.data);
      if (response.data.status == "OK") {
        setIncomeByCategory(response.data.data);
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function loadExpensesByEntity() {
    axios.get(config.BASE_URL + '/api/get-expenses-by-entity')
    .then(function(response) {
      console.log(response.data);
      if (response.data.status == "OK") {
        setExpensesByEntity(response.data.data);
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function loadItemExpenses() {
    axios.get(config.BASE_URL + '/api/get-expenses-by-item')
    .then(function(response) {
      console.log(response.data);
      if (response.data.status == "OK") {
        setItemExpenses(response.data.data);
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function labelFormatter(label, series) {
		return "<div style='font-size:8pt; text-align:center; padding:2px; color:white;'>" + label + "<br/>" + Math.round(series.percent) + "%</div>";
	}


  useEffect(() => {
    if (expensesByCategory.length > 0 && totalExpense > 0) {
      var chartData = [];
      for (var i in expensesByCategory) {
        var val = expensesByCategory[i];
        chartData.push({
          label: val.category,
          data: ((val.total * 100) / totalExpense).toFixed(2)
        });
      }
      setExpensesByCategoryChartData(chartData);
    }

  }, [totalExpense, expensesByCategory]);

  useEffect(() => {
    if (incomeByCategory.length > 0 && totalIncome > 0) {
      var chartData = [];
      for (var i in incomeByCategory) {
        var val = incomeByCategory[i];
        chartData.push({
          label: val.category,
          data: ((val.total * 100) / totalIncome).toFixed(2)
        });
      }
      setIncomeByCategoryChartData(chartData);
    }

  }, [totalIncome, incomeByCategory])

  useEffect(() => {
    loadStats();
    loadExpensesByCategory();
    loadIncomeByCategory();
    loadItemExpenses();
    loadExpensesByEntity();
  }, []);
  return (
    <>
      <Navbar />
      <div className="container mt-2">
        <div style={{textAlign: "center"}}>
          <h1>Dashboard</h1>
        </div>
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item">
            <a class={(currentTab == "info") ? "nav-link active" : "nav-link"} href="#" onClick={() => setCurrentTab("info")}>Info</a>
          </li>
          <li class="nav-item">
            <a class={(currentTab == "expenses-by-category") ? "nav-link active" : "nav-link"} href="#" onClick={() => setCurrentTab("expenses-by-category")}>Expenses By Category</a>
          </li>
          <li class="nav-item">
            <a class={(currentTab == "income-by-category") ? "nav-link active" : "nav-link"} href="#" onClick={() => setCurrentTab("income-by-category")}>Income By Category</a>
          </li>
          <li class="nav-item">
            <a class={(currentTab == "expenses-by-entity") ? "nav-link active" : "nav-link"} href="#" onClick={() => setCurrentTab("expenses-by-entity")}>Expenses By Entity</a>
          </li>
          <li class="nav-item">
            <a class={(currentTab == "item-expenses") ? "nav-link active" : "nav-link"} href="#" onClick={() => setCurrentTab("item-expenses")}>Item Expenses</a>
          </li>
        </ul>

        {currentTab == "info" &&
          <>
            <p><b>Total Transactions: </b>{totalTransactions}</p>
            <p><b>Total Income: </b>{totalIncome + "€"}</p>
            <p><b>Total Expense: </b>{totalExpense + "€"}</p>
            <p><b>Balance: </b>{(totalIncome - totalExpense).toFixed(2) + "€"}</p>
            <p><b>Average Spend Per Day: </b>{averageSpendPerDay.toFixed(2) +  "€"}</p>
            <p><b>Median Spend Per Day: </b>{medianSpendPerDay.toFixed(2) +  "€"}</p>
            <p><b>Average Spend Per Month: </b>{averageSpendPerMonth.toFixed(2) +  "€"}</p>
            <p><b>Average Nr. Of Transactions: </b>{averageNrOfTransactions.toFixed(2)}</p>
          </>
        }

        {currentTab == "expenses-by-category" &&
        <>
        <h5>Expenses By Category</h5>

        <div className="row">
          <div className="col-md-6">
            <table className="table table-sm small-table">
              <thead class="table-dark">
                <tr>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {expensesByCategory.map((item) => (
                  <tr>
                    <td>{item.category}</td>
                    <td>{item.total + "€"}</td>
                    <td>{((item.total * 100) / totalExpense).toFixed(2) + "%"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            {expensesByCategoryChartData.length > 0 &&
              <ReactFlot id="expenses-by-category-chart" options={expensesByCategoryChartOptions} data={expensesByCategoryChartData} width="300px" height="200px" />
            }
          </div>
        </div>
        </>
        }

        {currentTab == "income-by-category" &&
        <>
        <h5>Income By Category</h5>

        <div className="row">
          <div className="col-md-6">
            <table className="table table-sm small-table">
              <thead class="table-dark">
                <tr>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {incomeByCategory.map((item) => (
                  <tr>
                    <td>{item.category}</td>
                    <td>{item.total + "€"}</td>
                    <td>{((item.total * 100) / totalIncome).toFixed(2) + "%"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            {incomeByCategoryChartData.length > 0 &&
              <ReactFlot id="income-by-category-chart" options={incomeByCategoryChartOptions} data={incomeByCategoryChartData} width="300px" height="200px" />
            }
          </div>
        </div>
        </>
        }

        {currentTab == "expenses-by-entity" &&
        <>
        <h5>Expenses By Entity</h5>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-sm small-table">
              <thead class="table-dark">
                <tr>
                  <th>Entity</th>
                  <th>Amount</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {expensesByEntity.map((item) => (
                  <tr>
                    <td>{item.entity}</td>
                    <td>{item.total + "€"}</td>
                    <td>{((item.total * 100) / totalExpense).toFixed(2) + "%"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        </>
        }

        {currentTab == "item-expenses" &&
        <>
        <h5>Item Expenses</h5>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-sm small-table">
              <thead class="table-dark">
                <tr>
                  <th>Item</th>
                  <th>Amount</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {itemExpenses.map((item) => (
                  <tr>
                    <td>{item.description}</td>
                    <td>{item.total + "€"}</td>
                    <td>{((item.total * 100) / totalExpense).toFixed(2) + "%"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        </>
        }
      
      </div>
    </>
  )
}
