import React, {useState, useEffect} from 'react';
import Navbar from './Navbar';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import config from '../config';

export default function AddTransaction() {
  const [selectedType, setSelectedType] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [sumItems, setSumItems] = useState(0);
  const [newTransaction, setNewTransaction] = useState({
    description: "",
    amount: "",
    type: "",
    category: "",
    entity: "",
    date: convertDate(new Date())
  });

  const [newItem, setNewItem] = useState({
    description: "",
    quantity: "",
    amount: ""
  });

  const types = [
    { value: 'expense', label: 'Expense' },
    { value: 'income', label: 'Income' },
    { value: 'credit_payment', label: 'Credit Payment' }
  ];

  function changeNewTransactionDescription(e) {
    setNewTransaction({
      ...newTransaction,
      description: e.target.value
    })
  }

  function changeNewTransactionAmount(e) {
    setNewTransaction({
      ...newTransaction,
      amount: e.target.value
    })
  }

  function changeNewTransactionCategory(e) {
    setNewTransaction({
      ...newTransaction,
      category: e.target.value
    })
  }

  function changeNewTransactionEntity(e) {
    setNewTransaction({
      ...newTransaction,
      entity: e.target.value
    })
  }

  function changeNewTransactionType(item) {
    setSelectedType(item);
    setNewTransaction({
      ...newTransaction,
      type: item.value
    })
  }

  function changeNewTransactionDate(date) {
    setSelectedDate(date);
    setNewTransaction({
      ...newTransaction,
      date: convertDate(date)
    })
  }

  function changeItemAmount(e) {
    setNewItem({
      ...newItem,
      amount: e.target.value
    });
  }

  function changeItemDescription(e) {
    setNewItem({
      ...newItem,
      description: e.target.value
    });
  }

  function changeItemQuantity(e) {
    setNewItem({
      ...newItem,
      quantity: e.target.value
    });
  }

  function addItem() {
    setItems([
      ...items,
      newItem
    ]);
    setNewItem({
      description: "",
      quantity: "",
      amount: ""
    });
  }

  function deleteItem(index) {
    setItems(items.filter((item, i) => i !== index));
  }

  function submitTransaction() {
    axios.post(config.BASE_URL + '/api/add-transaction', {...newTransaction, items: items})
    .then(function (response) {
      if (response.data.status == "OK") {
        alert("Transaction added successfully");
        setNewTransaction({
          description: "",
          amount: "",
          type: "",
          category: "",
          entity: "",
          date: convertDate(new Date())
        });
        setItems([]);
        setSelectedType({});
        setSelectedDate(new Date());
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function (err) {
      alert(err.message);
    });
  }

  function convertDate(date) {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  }

  return (
    <>
      <Navbar />
      <div className="container mt-2">
        <div style={{textAlign: "center"}}>
          <h1>Add Transaction</h1>
        </div>
        <div className="form">
          <div className="form-group">
            <label>Description</label>
            <input type="text" className="form-control" value={newTransaction.description} onChange={changeNewTransactionDescription} />
          </div>
          <div className="form-group">
            <label>Amount</label>
            <input type="text" className="form-control" value={newTransaction.amount} onChange={changeNewTransactionAmount} />
          </div>
          <div className="form-group">
            <label>Type</label>
            <Select options={types} value={selectedType} onChange={changeNewTransactionType} />
          </div>
          <div className="form-group">
            <label>Category</label>
            <input type="text" className="form-control" value={newTransaction.category} onChange={changeNewTransactionCategory} />
          </div>
          <div className="form-group">
            <label>Entity</label>
            <input type="text" className="form-control" value={newTransaction.entity} onChange={changeNewTransactionEntity} />
          </div>
          <div className="form-group mb-3">
            <label>Date</label>
            <DatePicker className="form-control" selected={selectedDate} onChange={changeNewTransactionDate} dateFormat="yyyy-MM-dd" />
          </div>
          <h3>Items</h3>
          <table className="table table-sm add-items-table">
            <thead class="table-dark">
              <tr>
                <th style={{width: "190px"}} className="text-center">Description</th>
                <th style={{width: "75px"}} className="text-center">Quantity</th>
                <th style={{width: "75px"}} className="text-center">Amount</th>
                <th style={{width: "100px"}}></th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              {items.map((item, i) => (
                <tr key={i}>
                  <td className="text-center">{item.description}</td>
                  <td className="text-center">{item.quantity}</td>
                  <td className="text-center">{item.amount}</td>
                  <td className="text-center"><button className="btn btn-danger" onClick={() => deleteItem(i)}>Delete</button></td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td><input type="text" className="form-control" value={newItem.description} onChange={changeItemDescription} /></td>
                <td><input type="text" className="form-control" value={newItem.quantity} onChange={changeItemQuantity} /></td>
                <td><input type="text" className="form-control" value={newItem.amount} onChange={changeItemAmount} /></td>
                <td className="text-center"><button className="btn btn-success" onClick={addItem}>Add</button></td>
              </tr>
            </tfoot>
          </table>
          <p><b>Items Total: </b>{items.reduce( function(a, b){return a + Number(b['amount']);}, 0).toFixed(2)}</p>
          <div style={{textAlign: "right"}}>
            <button className="btn btn-primary" onClick={submitTransaction}>Submit</button>
          </div>
        </div>
      </div>
    </>
    
  )
}
