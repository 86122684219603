import React, {useEffect, useState} from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import config from '../config';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');
var bootprompt = require('bootprompt');

export default function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const transactionsPerPage = 10;
  const [selectedType, setSelectedType] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [allCategories, setAllCategories] = useState([{value: "-1", label: "All"}]);
  const [selectedCategory, setSelectedCategory] = useState({value: "-1", label: "All"});
  const [editTransaction, setEditTransaction] = useState({
    description: "",
    amount: "",
    type: "",
    category: "",
    entity: "",
    dt: ""
  });

  const [newItem, setNewItem] = useState({
    description: "",
    quantity: "",
    amount: ""
  });

  const types = [
    { value: 'expense', label: 'Expense' },
    { value: 'income', label: 'Income' },
    { value: 'credit_payment', label: 'Credit Payment' }
  ];

  function loadTransactions() {
    setTransactions([]);
    axios.get(config.BASE_URL + '/api/get-transactions', {
      params: {
        offset: page * transactionsPerPage, 
        limit: transactionsPerPage,
        category : selectedCategory.value != "-1" ? selectedCategory.label : "-1"
      }
    })
    .then(function(response) {
      console.log(response.data);
      if (response.data.status == "OK") {
        setTransactions(response.data.data.transactions);
        setTotalPages(Math.ceil(response.data.data.count / transactionsPerPage));
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function changePage({ selected }) {
    setPage(selected);
  }

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function deleteTransaction(id) {
    bootprompt.confirm({
      title: "Are you sure?",
      message: "Are you sure you want to delete this transaction?"
    }, (result) => {
      if (result) {
        axios.post(config.BASE_URL + '/api/delete-transaction', {id: id})
        .then(function(response) {
          if (response.data.status == "OK") {
            alert("Transaction deleted successfully!");
            loadTransactions();
          }
          else {
            alert(response.data.error);
          }
        });
      }
    });
  }

  function showEditTransaction(id) {
    axios.get(config.BASE_URL + '/api/get-transaction', {
      params: {
        id: id
      }
    })
    .then(function(response) {
      if (response.data.status == "OK") {
        setEditTransaction(response.data.data.transaction);
        setSelectedType(types.filter(type => type.value == response.data.data.transaction.type)[0]);
        setSelectedDate(new Date(response.data.data.transaction.dt));
        var items_arr = [];
        for (var i in response.data.data.items) {
          var item = response.data.data.items[i];
          items_arr.push({
            description: item.description,
            quantity: item.qtt,
            amount: item.amount
          });
        }
        setItems(items_arr);
        $('.editTransactionModal').modal('show');
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function submitEditTransaction(e) {
    e.preventDefault();
    axios.post(config.BASE_URL + '/api/edit-transaction', {...editTransaction, items: items})
    .then(function(response) {
      if (response.data.status == "OK") {
        alert("Transaction updated successfully!");
        closeEditTransactionModal();
        loadTransactions();
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function closeEditTransactionModal() {
    $('.editTransactionModal').modal('hide');
  }

  function changeEditTransactionDescription(e) {
    setEditTransaction({
      ...editTransaction,
      description: e.target.value
    })
  }

  function changeEditTransactionAmount(e) {
    setEditTransaction({
      ...editTransaction,
      amount: e.target.value
    })
  }

  function changeEditTransactionCategory(e) {
    setEditTransaction({
      ...editTransaction,
      category: e.target.value
    })
  }

  function changeEditTransactionEntity(e) {
    setEditTransaction({
      ...editTransaction,
      entity: e.target.value
    })
  }

  function changeEditTransactionType(item) {
    setSelectedType(item);
    setEditTransaction({
      ...editTransaction,
      type: item.value
    })
  }

  function changeEditTransactionDate(date) {
    setSelectedDate(date);
    setEditTransaction({
      ...editTransaction,
      dt: convertDate(date)
    })
  }

  function changeItemAmount(e) {
    setNewItem({
      ...newItem,
      amount: e.target.value
    });
  }

  function changeItemDescription(e) {
    setNewItem({
      ...newItem,
      description: e.target.value
    });
  }

  function changeItemQuantity(e) {
    setNewItem({
      ...newItem,
      quantity: e.target.value
    });
  }

  function addItem() {
    setItems([
      ...items,
      newItem
    ]);
    setNewItem({
      description: "",
      quantity: "",
      amount: ""
    });
  }

  function deleteItem(index) {
    setItems(items.filter((item, i) => i !== index));
  }

  function convertDate(date) {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  }

  function changeSelectedCategory(item) {
    console.log(item);
    setSelectedCategory(item);
  }

  function getAllCategories() {
    setAllCategories([]);
    axios.get(config.BASE_URL + '/api/get-all-categories')
    .then(function(response) {
      if (response.data.status == "OK") {
        var categories_arr = [];
        categories_arr.push({
          value: "-1",
          label: "All"
        });
        for (var i in response.data.data) {
          categories_arr.push({
            value: i,
            label: response.data.data[i].category
          });
        }
        setAllCategories(categories_arr);
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  useEffect(() => {
    loadTransactions();
  }, [page, selectedCategory]);

  useEffect(() => {
    setPage(0);
    loadTransactions();
    getAllCategories();
  }, []);
  return (
    <>
      <Navbar />
      <div className="container mt-2">
        <div style={{textAlign: "center"}}>
          <h1>Transactions</h1>
        </div>
        <div style={{width: "200px"}} className="mb-2">
          <Select options={allCategories} value={selectedCategory} onChange={changeSelectedCategory} />
        </div>
        <table className="table table-striped table-bordered mt-2">
          <thead class="table-dark">
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Type</th>
              <th>Category</th>
              <th>Entity</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => {
              var isExpense = transaction.type == "expense" || transaction.type == "credit_payment";
              var amountColor = isExpense ? "red" : "green";
              var minusSign = isExpense ? "-" : "";
              return (
                <tr key={transaction.id}>
                  <td>{transaction.dt.substring(0, 10)}</td>
                  <td>{transaction.description}</td>
                  <td>{types.filter((type) => type.value == transaction.type)[0].label}</td>
                  <td>{transaction.category}</td>
                  <td>{transaction.entity}</td>
                  <td style={{color: amountColor}}><b>{minusSign}{transaction.amount + "€"}</b></td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        Options
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#" onClick={(e) => {e.preventDefault(); showEditTransaction(transaction['id'])}}>Edit</a></li>
                        <li><a class="dropdown-item" href="#" onClick={() => deleteTransaction(transaction['id'])}>Delete</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={totalPages}
          onPageChange={changePage}
          containerClassName={"navigationButtons"}
          previousLinkClassName={"previousButton"}
          nextLinkClassName={"nextButton"}
          disabledClassName={"navigationDisabled"}
          activeClassName={"navigationActive"}
        />
      </div>
      <div class="modal editTransactionModal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content px-5">
            <div class="modal-header">
              <h5 class="modal-title">Edit Transaction</h5>
              <button type="button" class="btn-close" onClick={closeEditTransactionModal} aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form onSubmit={submitEditTransaction}>
                <div className="form-group">
                  <label>Description</label>
                  <input type="text" className="form-control" value={editTransaction.description} onChange={changeEditTransactionDescription} />
                </div>
                <div className="form-group">
                  <label>Amount</label>
                  <input type="text" className="form-control" value={editTransaction.amount} onChange={changeEditTransactionAmount} />
                </div>
                <div className="form-group">
                  <label>Type</label>
                  <Select options={types} value={selectedType} onChange={changeEditTransactionType} />
                </div>
                <div className="form-group">
                  <label>Category</label>
                  <input type="text" className="form-control" value={editTransaction.category} onChange={changeEditTransactionCategory} />
                </div>
                <div className="form-group">
                  <label>Entity</label>
                  <input type="text" className="form-control" value={editTransaction.entity} onChange={changeEditTransactionEntity} />
                </div>
                <div className="form-group mb-3">
                  <label>Date</label>
                  <DatePicker className="form-control" selected={selectedDate} onChange={changeEditTransactionDate} dateFormat="yyyy-MM-dd" />
                </div>
                <h3>Items</h3>
                <table className="table table-sm add-items-table">
                  <thead class="table-dark">
                    <tr>
                      <th style={{width: "190px"}} className="text-center">Description</th>
                      <th style={{width: "75px"}} className="text-center">Quantity</th>
                      <th style={{width: "75px"}} className="text-center">Amount</th>
                      <th style={{width: "100px"}}></th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    {items.map((item, i) => (
                      <tr key={i}>
                        <td className="text-center">{item.description}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td className="text-center">{item.amount}</td>
                        <td className="text-center"><button className="btn btn-danger" onClick={(e) => { e.preventDefault(); deleteItem(i);}}>Delete</button></td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td><input type="text" className="form-control" value={newItem.description} onChange={changeItemDescription} /></td>
                      <td><input type="text" className="form-control" value={newItem.quantity} onChange={changeItemQuantity} /></td>
                      <td><input type="text" className="form-control" value={newItem.amount} onChange={changeItemAmount} /></td>
                      <td className="text-center"><button className="btn btn-success" onClick={(e) => { e.preventDefault(); addItem();}}>Add</button></td>
                    </tr>
                  </tfoot>
                </table>
                <p><b>Items Total: </b>{items.reduce( function(a, b){return a + Number(b['amount']);}, 0).toFixed(2)}</p>
                <div style={{textAlign: "right"}}>
                  <button className="btn btn-primary" type="submit">Edit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
    
  )
}
